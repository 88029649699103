<script lang="ts" setup>
import { DemosService } from "classes/models/demos/demos-service";
import ConfirmDialog from "primevue/confirmdialog";
import type { Checklist } from "types/Checklist";
import { type SubmittedDocument } from "types/SubmittedDocument";
import { useModal } from "vue-final-modal";
import { Assignment } from "~/classes/models/assignments/assignment.model";
import { Classroom } from "~/classes/models/classrooms/classroom.model";
import {
Student,
type Students,
} from "~/classes/models/students/student.model";

import {
ref as firebaseFileref,
getDownloadURL,
uploadBytes,
} from "firebase/storage";
import { useFirebaseStorage } from "vuefire";
import UploadStudentSubmissionDialog from "~/components/UploadStudentSubmission/Dialog.vue";
import { DocumentState } from "~/types/enums/DocumentState";

import { addDoc, collection } from "firebase/firestore";
import { DocumentSubmissionType } from "~/types/enums/DocumentSubmissionType.enum";

declare const google: any;

const props = defineProps({
  checklist: {
    type: Object as PropType<Checklist>,
    required: false,
  },
  checklistId: {
    type: String,
    required: false,
  },
  classrooms: {
    type: Array as PropType<Classroom[]>,
    required: false,
  },
  assignments: {
    type: Array as PropType<Assignment[]>,
    required: false,
  },
  classroom: {
    type: Object as PropType<Classroom>,
    required: false,
  },
  assignment: {
    type: Object as PropType<Assignment>,
    required: false,
  },
  checklists: {
    type: Array as PropType<Checklist[]>,
    required: false,
  },
  students: {
    type: Array as PropType<Students>,
    required: false,
  },
  student: {
    type: Object as PropType<Student>,
    required: false,
  },
  isGoogleImportEnabled: {
    type: Boolean,
    required: false,
    default: true,
  },
  showSamples: {
    type: Boolean,
    required: false,
    default: false,
  },
  documentSubmissionType: {
    type: String as PropType<DocumentSubmissionType>,
    required: false,
    default: () => DocumentSubmissionType.finalDraft,
  },
  submittedDocumentState: {
    type: String as PropType<DocumentState>,
    required: false,
    default: () => DocumentState.submitted,
  },
  acceptedFileTypes: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => [
      "application/vnd.google-apps.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  },
  popupPlacement: {
    type: String,
    default: "bottom",
  },
});

const emit = defineEmits(["on-file-option-selected"]);

const selectedChecklist = ref<Checklist | undefined>(props.checklist);
const selectedStudent = ref<Student | undefined>(props.student);
const selectedClassroom = ref<Classroom | undefined>(props.classroom);
const selectedAssignment = ref<Assignment | undefined>(props.assignment);

const { createDocument } = useDocuments();

const fileRef = ref<HTMLElement | undefined>();

const uploadFile = () => {
  emit("on-file-option-selected");
  fileRef.value?.click();
};

const isUploading = computed((): boolean => {
  return filesBeingUploaded.value.length > 0;
});

// Every time we're uploading a file we'll add the document id to this array
// Once the api request finish processing we'll remove the id from the array
const filesBeingUploaded = ref<string[]>([]);

const files = ref<any[]>([]);

const onFileSelected = async (ev: any) => {
  files.value = [...ev.target.files];
  ev.target.files = null;
  ev.target.value = "";

  if (files.value.length == 0) {
    return;
  }

  if (props.checklists || props.students) {
    showConfigurationModal();
  } else {
    uploadFiles();
  }
};

const showConfigurationModal = (method: "google" | "computer" = "computer") => {
  const { open, close } = useModal({
    component: UploadStudentSubmissionDialog,
    attrs: {
      classrooms: props.classrooms,
      assignments: props.assignments,
      checklists: props.checklists,
      students: props.students,
      onUpload: (data) => {
        const { checklist, student, classroom, assignment } = data;

        selectedClassroom.value = classroom ?? props.classroom;
        selectedAssignment.value = assignment ?? props.assignment;

        selectedChecklist.value = checklist;
        selectedStudent.value = student;

        close();
        uploadFiles(method);
      },
      onClose: () => {
        close();
      },
    },
  });

  open();
};

const uploadFiles = async (method: "google" | "computer" = "computer") => {
  const documentParams = {} as any;

  const uid = useCurrentUID();

  documentParams.userId = uid;

  if (selectedClassroom.value) {
    documentParams.classroom = {
      id: selectedClassroom.value.id,
      name: selectedClassroom.value.name,
    };
  }

  if (selectedAssignment.value) {
    documentParams.assignment = {
      id: selectedAssignment.value.id,
      name: selectedAssignment.value.name,
    };

    if (selectedAssignment.value.checklistId) {
      const checklistPath =
        props.checklists?.find(
          (checklist) => checklist.id == selectedAssignment.value!.checklistId
        )?.path ?? "/checklists";

      documentParams.checklist = {
        id: selectedAssignment.value.checklistId,
        path: checklistPath,
      };
    }

    if (selectedAssignment.value.classroomId) {
      documentParams.classroom = {
        id: selectedAssignment.value.classroomId,
        name: selectedAssignment.value.classroomName ?? "",
      };
    }
  }

  documentParams.allowFinalDraftGradedState =
    selectedAssignment.value?.allowFinalDraftGradedState != undefined
      ? selectedAssignment.value?.allowFinalDraftGradedState
      : selectedClassroom.value?.allowFinalDraftGradedState;

  if (selectedChecklist.value) {
    documentParams.checklist = {
      id: selectedChecklist.value.id,
      path: selectedChecklist.value.path,
    };
  }

  if (selectedStudent.value) {
    documentParams.student = {
      id: selectedStudent.value.id,
      name: selectedStudent.value.name,
    };

    documentParams.userId = selectedStudent.value.userId;
  }

  documentParams.documentSubmissionType = props.documentSubmissionType;

  for (const file of files.value) {
    documentParams.googleDriveFileId = file.fileId;

    const documentId = await createDocument(file.name, documentParams);
    filesBeingUploaded.value.push(documentId);

    try {
      const headers = await useApiHeaders();

      if (
        [
          "application/vnd.google-apps.document",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(file.type) != true
      ) {
        filesBeingUploaded.value = [
          ...filesBeingUploaded.value.filter((id) => id !== documentId),
        ];

        if (
          method == "google" &&
          file.type != "application/vnd.google-apps.spreadsheet"
        ) {
          // Note: This URL and method of downloading files works for small to medium-sized files.
          // For larger files, you should use the "files.get" method with the parameter "alt=media"
          // and handle the stream appropriately.

          const response = await fetch(
            `https://www.googleapis.com/drive/v3/files/${file.fileId}?alt=media`,
            {
              headers: new Headers({
                Authorization: "Bearer " + accessToken.value,
              }),
            }
          );

          if (!response.ok) {
            throw new Error(
              `Server responded with ${response.status}: ${response.statusText}`
            );
          }

          const blob = await response.blob();

          const customFile = {
            data: blob,
            name: file.name,
            size: blob.size,
            type: file.type,
          } as CustomFile;

          await uploadDocumentToFirestore({
            customFile,
            documentId,
          });

          continue;
        }

        if (
          method == "google" &&
          file.type == "application/vnd.google-apps.spreadsheet"
        ) {
          // Export the google drive file as an excel file
          const response = await fetch(
            `https://www.googleapis.com/drive/v3/files/${file.fileId}/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
            {
              headers: new Headers({
                Authorization: "Bearer " + accessToken.value,
              }),
            }
          );

          if (!response.ok) {
            throw new Error(
              `Server responded with ${response.status}: ${response.statusText}`
            );
          }

          const blob = await response.blob();

          const customFile = {
            data: blob,
            name: file.name,
            size: blob.size,
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          } as CustomFile;

          await uploadDocumentToFirestore({
            customFile,
            documentId,
          });

          continue;
        }

        await uploadDocumentToFirestore({
          file,
          documentId,
        });

        continue;
      }

      if (method == "google") {
        useFetch(`/api/documents/${documentId}/parse/google`, {
          method: "POST",
          headers: headers,
          body: {
            fileId: file.fileId,
            fileName: file.name,
            fileType: file.type,
            submittedDocumentState:
              props.submittedDocumentState ?? DocumentState.submitted,
            accessToken: accessToken.value,
          },
        }).then(() => {
          filesBeingUploaded.value = [
            ...filesBeingUploaded.value.filter((id) => id !== documentId),
          ];
        });
      } else {
        // Check if the file type is a word document or some other type
        // If it's not a word document we stop

        // Check if the file size is greater than 4.25mb
        // If it is we need to upload it to firebase and pass a filePath to the parse endpoint
        // If it is not, we can post the file to our servers
        // Vercel has a payload size limit of 4.5mb
        if (file.size > 4.25 * 1024 * 1024) {
          const filePath = `temp-documents/${documentId}/${file.name}`;

          const firebaseStorage = useFirebaseStorage();
          const fileRef = firebaseFileref(firebaseStorage, filePath);
          await uploadBytes(fileRef, file);

          $fetch(`/api/documents/${documentId}/parse/firebase-storage`, {
            method: "POST",
            headers: headers,
            body: {
              method,
              firebaseStoragePath: `${filePath}`,
              fileName: file.name,
              fileType: file.type,
              submittedDocumentState:
                props.submittedDocumentState ?? DocumentState.submitted,
            },
          }).finally(() => {
            filesBeingUploaded.value = [
              ...filesBeingUploaded.value.filter((id) => id !== documentId),
            ];
          });
        } else {
          const formData = new FormData();

          formData.append("file", file);
          formData.append("fileName", file.name);
          formData.append("fileType", file.type);
          formData.append("method", method);
          formData.append(
            "submittedDocumentState",
            props.submittedDocumentState ?? DocumentState.submitted
          );

          $fetch(`/api/documents/${documentId}/parse`, {
            method: "POST",
            headers: headers,
            body: formData,
          }).finally(() => {
            filesBeingUploaded.value = [
              ...filesBeingUploaded.value.filter((id) => id !== documentId),
            ];
          });
        }
        continue;
      }
    } catch (error) {
      console.error("Error uploading file:", error);

      filesBeingUploaded.value = [
        ...filesBeingUploaded.value.filter((id) => id !== documentId),
      ];
    }
  }

  files.value = [];
};

const uploadDocumentToFirestore = async ({
  file,
  customFile,
  documentId,
}: {
  file?: File;
  customFile?: CustomFile;
  documentId: string;
}) => {
  const uid = useCurrentUID();

  if (!uid) return;

  const finalFile = file ?? customFile;

  if (finalFile == undefined) return;

  const mediaObject = await useFileUploader().createMediaObject(
    finalFile,
    `/documents/${documentId}`,
    false
  );

  // Check if file is a File or CustomFile

  if (!file && !customFile) return;

  const task = await useFileUploader().uploadFile(
    file ?? customFile!.data,
    mediaObject
  );
  mediaObject.mediaHref = await getDownloadURL(task.ref);

  // I should create the media object instead. Let's see.
  const revisionRef = collection(
    useFirestore(),
    `/documents/${documentId}/revisions`
  );

  await addDoc(revisionRef, {
    documentId,
    externalId: documentId,
    lastUpdatedTimestamp: Date.now(),
    fileObject: mediaObject,
  });

  // Update the document state
  await useDocuments().updateDocumentStateById(
    documentId,
    props.submittedDocumentState
  );
};

const onPickGoogle = () => {
  emit("on-file-option-selected");
  signInToGoogle(); // User is not signed in, handle authentication
};

const accessToken = ref<any | undefined>(undefined);

const signInToGoogle = async () => {
  const tokenClientState = useState("tokenClient");

  // Request an access token.
  (tokenClientState.value as any).callback = async (response: any) => {
    if (response.error !== undefined) {
      throw response;
    }
    const accessToken = response.access_token;
    openGooglePicker(accessToken);
  };

  if (accessToken.value === null) {
    // Prompt the user to select a Google Account and ask for consent to share their data
    // when establishing a new session.
    (tokenClientState.value as any).requestAccessToken({ prompt: "consent" });
  } else {
    // Skip display of account chooser and consent dialog for an existing session.
    (tokenClientState.value as any).requestAccessToken({ prompt: "" });
  }
};

const openGooglePicker = (token: any) => {
  accessToken.value = token;

  const view = new google.picker.DocsView()
    .setIncludeFolders(true)
    .setSelectFolderEnabled(false)
    .setMimeTypes(props.acceptedFileTypes.join(","));

  const picker = new google.picker.PickerBuilder()
    .addView(view)
    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
    .setOAuthToken(token)
    .setDeveloperKey(useRuntimeConfig().public.googleApiKey)
    .setCallback(pickerCallback)
    .setAppId(useRuntimeConfig().public.googleClientId)
    .build();
  picker.setVisible(true);
};

const pickerCallback = async (data: any) => {
  if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
    const documents = data[google.picker.Response.DOCUMENTS];

    // Handle the selected file here

    const googleFiles = [] as any[];
    for (const doc of documents) {
      googleFiles.push({
        fileId: doc.id,
        name: doc.name,
        type: doc.mimeType,
      });
    }

    files.value = googleFiles;

    if (props.checklists || props.students) {
      showConfigurationModal("google");
    } else {
      uploadFiles("google");
    }
  }
};

const sampleDocumentsStore = useSampleDocuments();
const { sampleDocuments } = storeToRefs(sampleDocumentsStore);

const importSampleDocument = async (sampleDocument: SubmittedDocument) => {
  const userId = useCurrentUID();

  if (!userId) return;

  if (!sampleDocument.id) return;

  DemosService.copySampleToUser(sampleDocument.id, userId);
};

const allSamples = computed(() => {
  sampleDocuments.value.sort((a, b) => {
    return (a.sampleDisplayOrder ?? 0) - (b.sampleDisplayOrder ?? 0);
  });

  return sampleDocuments.value;
});

const firstSample = computed(() => {
  return allSamples.value[0];
});

const remaiingSamples = computed(() => {
  // return everythingf but the fist sample
  return allSamples.value.slice(1);
});

const acceptedFileTypeLabels = computed(() => {
  return props.acceptedFileTypes
    .map((fileType) => {
      switch (fileType) {
        case "application/vnd.google-apps.document":
          return "Google Doc";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "Word Document";
        case "application/pdf":
          return "PDF";
        case "image/*":
          return "Images";
        case "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "Excel";
        case "application/vnd.google-apps.spreadsheet":
          return "Google Sheet";
        default:
          return "Unknown";
      }
    })
    .filter((label) => label != "Unknown");
});
</script>

<template>
  <VDropdown
    :disabled="isGoogleImportEnabled != true"
    :placement="popupPlacement"
  >
    <BaseTextButton
      :show-spinner="isUploading"
      :class="{
        '!justify-start': true,
      }"
    >
      <ConfirmDialog group="upload">
        <template #message />
      </ConfirmDialog>

      <icon name="material-symbols:upload" size="20" />
      <span class="ml-1">
        <slot> Upload Student Submission </slot>
      </span>
      <input
        ref="fileRef"
        class="hidden"
        type="file"
        :accept="acceptedFileTypes.join(',')"
        multiple
        @change="onFileSelected"
      >
    </BaseTextButton>
    <template #popper>
      <div class="flex flex-col bg-surface">
        <div class="border-b">
          <BaseTextButton
            v-tooltip="acceptedFileTypeLabels.join(', ')"
            class="w-full"
          >
            <div class="w-full flex flex-row items-center justify-left">
              <icon name="material-symbols:info-outline" size="20" />
              <span class="ml-4 text-[14px] leading-1 mt-[1px]">
                Accepted File Types
              </span>
            </div>
          </BaseTextButton>
        </div>
        <BaseTextButton v-close-popper class="w-full" @click="uploadFile">
          <div class="flex flex-row items-center w-full">
            <icon name="material-symbols:upload" size="20" />
            <span class="ml-4"> From Computer </span>
          </div>
        </BaseTextButton>
        <BaseTextButton v-close-popper class="w-full" @click="onPickGoogle">
          <div class="flex flex-row items-center w-full">
            <icon name="logos:google-drive" size="20" />

            <span class="ml-4"> From Google Drive </span>
          </div>
        </BaseTextButton>
        <BaseTextButton
          v-if="showSamples && firstSample"
          :key="firstSample.id"
          v-close-popper
          class="w-[220px]"
          :data-step-id="`sample-document-button-${firstSample.name
            .toLowerCase()
            .split(' ')
            .join('-')}`"
          @click="importSampleDocument(firstSample)"
        >
          <div class="flex flex-row items-center w-full">
            <icon name="mi:document" size="20" />

            <span class="ml-4"> {{ firstSample.name }} </span>
          </div>
        </BaseTextButton>
        <VMenu v-if="showSamples" placement="right">
          <BaseTextButton>
            <div class="flex flex-row items-center w-full">
              <icon name="material-symbols:folder-outline" size="20" />

              <span class="ml-4"> Samples </span>
            </div>
          </BaseTextButton>

          <template #popper>
            <BaseTextButton
              v-for="sample in remaiingSamples"
              :key="sample.id"
              v-close-popper
              class="w-[220px]"
              :data-step-id="`sample-document-button-${sample.name
                .toLowerCase()
                .split(' ')
                .join('-')}`"
              @click="importSampleDocument(sample)"
            >
              <div class="flex flex-row items-center w-full">
                <icon name="mi:document" size="20" />

                <span class="ml-4"> {{ sample.name }} </span>
              </div>
            </BaseTextButton>
          </template>
        </VMenu>
      </div>
    </template>
  </VDropdown>
</template>

<style scoped></style>
