<script lang="ts" setup>
import { VueFinalModal } from "vue-final-modal";

const props = defineProps({
  document: {
    type: Object as PropType<SubmittedDocument>,
    required: true,
  },
});

const submittedDocument = ref<SubmittedDocument>({ ...props.document });

const emit = defineEmits(["close"]);

const userClassroomStore = useUserClassroomsDataStore();
const { classrooms, allAssignments, allStudents } =
  storeToRefs(userClassroomStore);

const onChangeClassroom = () => {
  const matchingClassroom = classrooms.value.find(
    (classroom) => classroom.id === submittedDocument.value.classroomId
  );

  if (matchingClassroom == undefined) return;

  submittedDocument.value.classroomName = matchingClassroom.label;
};

const onChangeStudent = () => {
  const matchingStudent = allStudents.value.find(
    (student) => student.id === submittedDocument.value.studentId
  );

  if (matchingStudent == undefined) return;

  submittedDocument.value.studentName = matchingStudent.name;
};

const onChangeAssignment = () => {
  const matchingAssignment = allAssignments.value.find(
    (assignment) => assignment.id === submittedDocument.value.assignmentId
  );

  if (matchingAssignment == undefined) return;

  submittedDocument.value.assignmentName = matchingAssignment.name;
  submittedDocument.value.dueTime = matchingAssignment.dueTimestamp;
  submittedDocument.value.checklistId =
    matchingAssignment.checklistId ?? undefined;
  submittedDocument.value.checklistPath =
    matchingAssignment.checklistPath ?? undefined;
};

const sortedClassrooms = computed(() => {
  return classrooms.value.sort(
    (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
  );
});

const classroomAssignments = computed(() => {
  if (submittedDocument.value.classroomId == undefined) return [];

  return useSortAssignments(
    allAssignments.value.filter(
      (assignment) =>
        assignment.classroomId === submittedDocument.value.classroomId
    )
  );
});

const classroomStudents = computed(() => {
  if (submittedDocument.value.classroomId == undefined) return [];

  return allStudents.value
    .filter((student) =>
      student.classroomIds.includes(submittedDocument.value.classroomId!)
    )
    .sort((a, b) => a.name.localeCompare(b.name));
});

const isSavingDocument = ref(false);

const saveDocument = async () => {
  if (submittedDocument.value.classroomId == undefined) {
    useBaseToast("Please select a classroom", "error");
    return;
  }

  if (
    submittedDocument.value.studentId == undefined ||
    submittedDocument.value.assignmentId == undefined
  ) {
    useBaseToast("Please select a student and an assignment", "error");
    return;
  }

  isSavingDocument.value = true;

  const hasSameChecklist =
    props.document.checklistId === submittedDocument.value.checklistId;

  await useDocuments().updateDocument(submittedDocument.value);

  if (hasSameChecklist) {
    onSaveSuccess();
    return;
  }

  if (
    submittedDocument.value.checklistId == undefined ||
    submittedDocument.value.id == undefined
  ) {
    onSaveSuccess();
    return;
  }

  // Trigger the change checklist process.
  await useApplyChecklistToDocuments().applyChecklistToDocumentNow(
    submittedDocument.value.checklistId!,
    submittedDocument.value.checklistPath ?? "/checklists",
    submittedDocument.value.id!
  );

  onSaveSuccess();
};

const onSaveSuccess = () => {
  useBaseToast("Document saved successfully", "success");
  emit("close");
};
</script>

<template>
  <VueFinalModal
    class="z-[99]"
    content-class="z-[999] w-full max-w-[600px] h-[500px] alert-modal-content bg-surface overflow-hidden rounded shadow absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
  >
    <NuxtLayout
      name="dialog"
      class="h-full"
      title="Edit Submission"
      @close="$emit('close')"
    >
      <div class="p-4 pb-8 flex flex-col h-full overflow-auto">
        <BaseFormComponent>
          <template #label> Classroom </template>
          <BaseSelect
            v-model="submittedDocument.classroomId"
            :options="sortedClassrooms"
            value-prop="id"
            label="label"
            :searchable="true"
            @update:model-value="onChangeClassroom"
          />
        </BaseFormComponent>
        <TransitionsCollapsible>
          <div
            v-if="submittedDocument.classroomId"
            :key="submittedDocument.classroomId"
            class="flex flex-col"
          >
            <BaseFormComponent>
              <template #label> Student </template>
              <BaseSelect
                v-model="submittedDocument.studentId"
                :options="classroomStudents"
                value-prop="id"
                label="name"
                :searchable="true"
                @update:model-value="onChangeStudent"
              />
            </BaseFormComponent>
            <BaseFormComponent>
              <template #label> Assignment </template>
              <BaseSelect
                v-model="submittedDocument.assignmentId"
                :options="classroomAssignments"
                value-prop="id"
                label="name"
                :searchable="true"
                @update:model-value="onChangeAssignment"
              />
            </BaseFormComponent>
          </div>
        </TransitionsCollapsible>
        <BaseButton :show-spinner="isSavingDocument" @click="saveDocument">
          Save
        </BaseButton>
      </div>
    </NuxtLayout>
  </VueFinalModal>
</template>

<style scoped></style>
