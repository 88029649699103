<script lang="ts" setup>
import { VueFinalModal } from "vue-final-modal";

import { Student, type Students } from "classes/models/students/student.model";
import type { Checklist, Checklists } from "types/Checklist";
import {
  Classroom,
  type Classrooms,
} from "classes/models/classrooms/classroom.model";
import {
  Assignment,
  type Assignments,
} from "classes/models/assignments/assignment.model";

const props = defineProps({
  checklists: {
    type: Array as PropType<Checklists>,
    required: false,
  },
  classrooms: {
    type: Array as PropType<Classrooms>,
    required: false,
  },
  assignments: {
    type: Array as PropType<Assignments>,
    required: false,
  },
  students: {
    type: Array as PropType<Students>,
    required: false,
  },
});

const emit = defineEmits(["close", "upload"]);

const selectedChecklist = ref<Checklist | undefined>(undefined);
const selectedClassroom = ref<Classroom | undefined>(undefined);
const selectedAssignment = ref<Assignment | undefined>(undefined);

const selectedStudent = ref<Student | undefined>(undefined);

const onChangedSelectedChecklist = (checklistId: string) => {
  selectedChecklist.value = props.checklists?.find(
    (checklist) => checklist.id == checklistId
  );
};

const onChangedSelectedStudent = (studentId: string) => {
  selectedStudent.value = props.students?.find(
    (student) => student.id == studentId
  );
};

const onUpload = () => {
  if (props.checklists && selectedChecklist.value == undefined) {
    useBaseToast("Please select a checklist.", "error");
    return;
  }

  if (showStudentSelect.value == true && selectedStudent.value == undefined) {
    useBaseToast("Please select a student.", "error");
    return;
  }

  emit("upload", {
    classroom: selectedClassroom.value,
    assignment: selectedAssignment.value,
    checklist: selectedChecklist.value,
    student: selectedStudent.value,
  });
};

const showClassroomSelect = computed(() => {
  return props.classrooms != undefined && props.classrooms.length > 0;
});

const showAssignmentSelect = computed(() => {
  return (
    props.assignments != undefined &&
    props.assignments.length > 0 &&
    selectedClassroom.value != undefined
  );
});

const showStudentSelect = computed(() => {
  return (
    props.students != undefined &&
    props.students.length > 0 &&
    (props.classrooms == undefined || selectedAssignment.value != undefined)
  );
});

const onChangedSelectedClassroom = (classroomId: string) => {
  selectedClassroom.value = props.classrooms?.find(
    (classroom) => classroom.id == classroomId
  );
};

const onChangedSelectedAssignment = (assignmentId: string) => {
  selectedAssignment.value = props.assignments?.find(
    (assignment) => assignment.id == assignmentId
  );

  if (
    selectedAssignment.value != undefined &&
    selectedAssignment.value.checklistId != undefined
  ) {
    onChangedSelectedChecklist(selectedAssignment.value.checklistId);
  }
};

const assignmentsToShow = computed(() => {
  if (selectedClassroom.value == undefined) return [];

  const assignmentsList = props.assignments?.filter(
    (assignment) => assignment.classroomId == selectedClassroom.value?.id
  );

  return useSortAssignments(assignmentsList ?? []);
});

const studentsToShow = computed(() => {
  if (props.classrooms == undefined) return props.students;

  if (
    selectedAssignment.value == undefined &&
    selectedClassroom.value?.id != undefined
  )
    return [];

  return props.students?.filter((student) =>
    student.classroomIds.includes(selectedClassroom.value!.id!)
  );
});

const sortedChecklists = computed(() => {
  const c = useSortChecklists(props.checklists ?? []);
  return c;
});
</script>

<template>
  <VueFinalModal
    class="dialog-modal"
    content-class="alert-modal-content bg-surface p-4 rounded shadow absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
  >
    <div
      class="flex flex-col justify-between w-[600px] h-[300px] overflow-auto"
      :class="{
        'h-[500px]': showClassroomSelect,
      }"
    >
      <div class="flex flex-col">
        <BaseFormComponent v-if="showClassroomSelect">
          <template #label> Choose a Classroom (optional) </template>
          <BaseSelect
            :options="classrooms"
            value-prop="id"
            label="label"
            @change="onChangedSelectedClassroom"
            :searchable="true"
          />
        </BaseFormComponent>

        <BaseFormComponent v-if="showAssignmentSelect">
          <template #label> Choose an Assignment </template>
          <BaseSelect
            :options="assignmentsToShow"
            value-prop="id"
            label="name"
            @change="onChangedSelectedAssignment"
            :searchable="true"
          />
        </BaseFormComponent>

        <BaseFormComponent v-if="showStudentSelect">
          <template #label> Choose a Student </template>
          <BaseSelect
            :options="studentsToShow"
            value-prop="id"
            label="name"
            @change="onChangedSelectedStudent"
            :searchable="true"
          />
        </BaseFormComponent>

        <div
          v-if="showClassroomSelect"
          class="h-[1px] bg-medium-default rounded mb-6 mt-4"
        >
          &nbsp;
        </div>

        <BaseFormComponent v-if="checklists">
          <template #label> Choose a Checklist </template>
          <BaseSelect
            :key="selectedChecklist?.id"
            :options="sortedChecklists"
            value-prop="id"
            label="title"
            :model-value="selectedChecklist?.id"
            @change="onChangedSelectedChecklist"
            :searchable="true"
          />
        </BaseFormComponent>
      </div>
      <div class="flex flex-row items-center justify-end">
        <BaseTextButton @click="$emit('close')" color="danger" class="mr-2">
          Cancel
        </BaseTextButton>
        <BaseButton @click="onUpload"> Upload </BaseButton>
      </div>
    </div>
  </VueFinalModal>
</template>

<style scoped></style>
